import { Component, Vue } from "vue-property-decorator";

import { STHeader,STFooter } from "@/layout";
import { APLightbox, APResetPwd } from "@/components";
import { StudentModel } from "@/models";
import { StCommon } from "../Common";
import { ErrorCode, ErrorMessage } from "@/constant";

export class InfoForm {
  acc = '';
  studentid = '';
  name = '';
  phone = '';
  parentName = '';
  parentPhone = '';
  liveCity = '';
  region = '';
  address = '';
  pic = '';
  city = '';
  school = '';
  stid = '';
  note = '';
}

type response = { [key: string]: unknown };

@Component<ApStudentIndexController>({
  components: {
    STHeader, APLightbox,APResetPwd, STFooter
  }
})
export default class ApStudentIndexController extends Vue {
  /*定義data 變數 */
  private doubleClick = false;
  private infoForm = new InfoForm();
  private stid = window.localStorage.getItem('student_stid') as string;
  private token = window.localStorage.getItem('studentToken') as string;

  private lightbox: { showLB: boolean, errorMsg: string } = {
    showLB: false, 
    errorMsg:  '',
};

  public async created() {
    const item: { [key: string]: string } = {
      stid: this.stid,
      token: this.token,
    }
    const editData = await StudentModel.stGet(item) as response;
    this.assignDataToForm(editData);
  }

  private assignDataToForm(data: response) {
    StCommon.checkToken(data.ERR_CODE as number);

    const item = data.info as { [key: string]: string };

    this.infoForm.stid = item.stid;
    this.infoForm.acc = item.acc;
    this.infoForm.studentid = item.studentid;
    this.infoForm.name = item.name;
    this.infoForm.phone = item.phone;
    this.infoForm.parentName = item.parentName;
    this.infoForm.parentPhone = item.parentPhone;
    this.infoForm.liveCity = item.liveCity;
    this.infoForm.region = item.region
    this.infoForm.address = item.address;
    this.infoForm.pic = item.pic;
    this.infoForm.city = item.city;
    this.infoForm.school = item.school;
    this.infoForm.note = item.note;
  }

  public async openResetPwdLB() {
    this.$emit("openResetPwd", true, this.infoForm.acc);
  }

  public async validateAndSubmitPwd(newPwd: string) {
    const item: { [key: string]: string } = {
      stid: this.stid,
      pwd: newPwd,
      token: this.token,
    }
    const errNo = await StudentModel.stEditPwd(item);
    switch (errNo) {
      case ErrorCode.InvalidToken:
        StCommon.logout();
        break;
      case ErrorCode.Success:
        this.doubleClick = false;
        this.$router.go(0); //reload page
        break;
      default:
        this.lightbox.errorMsg = ErrorMessage[errNo];
        this.lightbox.showLB = true;
        this.doubleClick = false;
        break;
    }
  }

  private closeLB() {
    this.lightbox.showLB = false;
  }
}